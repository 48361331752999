import React from 'react';
import { Grid, List, ListItem, ListItemIcon, ListItemText, Box, Button, Typography, IconButton, Tooltip } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
    FaTasks,
    FaLock,
    FaChartLine,
    FaCog,
    FaTachometerAlt,
    FaServer,
    FaStoreAlt,
} from 'react-icons/fa';
import OikosLogo from "../../images/logo/OikosDark.svg";
import SyncDriveLogo from "../../images/logo/SyncDriveLight.svg";
import PlatusLogo from "../../images/logo/PlatusNew.svg";
import { useNavigate } from 'react-router-dom';
import { OpenInNew } from '@mui/icons-material';
import { IoMdPricetags } from 'react-icons/io';

const groups = {
    'Deployment': [
        'Smarter Deployments',
        'Re-deploy and Revert',
        'Location-Based Deployment',
        'Remote Build',
        'Built-in Dockerization',
    ],
    'Access Control & Security': [
        'Members & RBAC',
        'Secrets Management',
        'DDoS Protection',
        'Private Repository Support',
    ],
    'Monitoring & Insights': [
        'Real-Time Alerts',
        'Application Logs',
        'Performance Metrics',
        'User Analytics',
    ],
    'Configuration & Management': [
        'Global Variables',
        'Environment Variables',
        'Config Templates',
        'Manage Environments',
    ],
    'Performance Optimization': [
        'Performance and Reliability',
        'Scaling Apps',
        'Geolocation Routing',
        'Traffic Control',
    ],
    'Application Resilience': [
        'Kubernetes Replicas',
        'Backup Regions',
    ],
    'Marketplace & Integrations': [
        'App Marketplace',
        'Webhooks',
        'BYOH (Bring Your Own Host)',
        'BYOC (Bring Your Own Cluster)',
    ],
};

// const images = [
//     "https://react-nife-2024.s3.ap-south-1.amazonaws.com/oikos-features/Speed test-rafiki.svg",
//     "https://react-nife-2024.s3.ap-south-1.amazonaws.com/oikos-features/api.svg",
//     "https://react-nife-2024.s3.ap-south-1.amazonaws.com/oikos-features/Data extraction-amico.svg",
//     "https://react-nife-2024.s3.ap-south-1.amazonaws.com/oikos-features/Data extraction-rafiki.svg",
//     "https://react-nife-2024.s3.ap-south-1.amazonaws.com/oikos-features/Investment data-amico.svg",
//     "https://react-nife-2024.s3.ap-south-1.amazonaws.com/oikos-features/Speed%20test-pana.svg",
//     "https://react-nife-2024.s3.ap-south-1.amazonaws.com/oikos-features/Server-cuate.svg",
//     "https://react-nife-2024.s3.ap-south-1.amazonaws.com/oikos-features/Server-bro.svg",
//     "https://react-nife-2024.s3.ap-south-1.amazonaws.com/oikos-features/Server-amico.svg",
//     "https://react-nife-2024.s3.ap-south-1.amazonaws.com/oikos-features/pull-request.svg",
//     "https://react-nife-2024.s3.ap-south-1.amazonaws.com/oikos-features/Online world-bro.svg",
//     "https://react-nife-2024.s3.ap-south-1.amazonaws.com/oikos-features/Online world-amico.svg",
//     "https://react-nife-2024.s3.ap-south-1.amazonaws.com/oikos-features/Investment data-rafiki.svg",
//     "https://react-nife-2024.s3.ap-south-1.amazonaws.com/oikos-features/Investment data-amico.svg",
// ]

const icons = {
    'Deployment': <FaTasks size={20} />,
    'Access Control & Security': <FaLock size={20} />,
    'Monitoring & Insights': <FaChartLine size={20} />,
    'Configuration & Management': <FaCog size={20} />,
    'Performance Optimization': <FaTachometerAlt size={20} />,
    'Application Resilience': <FaServer size={20} />,
    'Marketplace & Integrations': <FaStoreAlt size={20} />,
};

const GroupComponent = () => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/oikos/features');
    };

    const handleGroupClick = (group) => {
        const formattedGroup = group.toLowerCase().replace(/ /g, '-').replace(/&/g, 'and');
        navigate(`/oikos/features#${formattedGroup}`);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
                <List dense>
                    {Object.keys(groups).map((group) => (
                        <ListItem
                            key={group}
                            onClick={() => handleGroupClick(group)}
                            button
                        >
                            <ListItemIcon>
                                <IconButton sx={{ borderRadius: "12px", border: "1px solid rgba(255, 255, 255, 0.3)" }}>
                                    {icons[group]}
                                </IconButton>
                            </ListItemIcon>
                            <ListItemText primary={group} />
                        </ListItem>
                    ))}
                </List>
                <Button
                    variant="contained"
                    endIcon={<ArrowForwardIcon />}
                    onClick={handleClick}
                    sx={{ mt: 2 }} // Add margin-top for spacing
                >
                    View all features
                </Button>
                 <Button
                    variant="outlined"
                    endIcon={<ArrowForwardIcon />}
                    onClick={()=> navigate("/runtimes")}
                    sx={{ mt: 2 }} // Add margin-top for spacing
                >
                    View all runtime
                </Button>
            </Grid>
            <Grid item xs={12} lg={6} sx={{ borderLeft: "1px solid #5e6379", }}>
                <Box sx={{ p: 2 }}>
                    <Typography variant='caption'>Products</Typography>

                    <Box>


                        <Grid
                            container
                            direction="row"
                            sx={{
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >

                            <div>
                                <a href="/oikos">
                                    <img alt="Oikos logo" src={OikosLogo} style={{ height: "40px", objectFit: "cover", textAlign: "center" }} />
                                </a>
                            </div>
                            <Tooltip title="Dashboard">
                                <IconButton onClick={() => window.open("https://launch.nife.io/", "_blank")}>
                                    <OpenInNew />
                                </IconButton>
                            </Tooltip>


                        </Grid>
                        <Typography variant="body2" sx={{ opacity: "0.6", fontSize: "12px", mt: 1 }}>
                            Your applications deserve a comfortable abode.</Typography>

                        <Button

                            onClick={() => window.open("https://docs.nife.io/docs/Introduction/Nife/", "_blank")}
                            startIcon={<svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M7 18H17V16H7V18Z" fill="currentColor"></path><path d="M17 14H7V12H17V14Z" fill="currentColor"></path><path d="M7 10H11V8H7V10Z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z" fill="currentColor"></path></svg>}
                            size="small" variant="text" >Docs</Button>

                        <Button
                            onClick={() => window.open("/oikos/pricing", "_blank")}
                            startIcon={<IoMdPricetags />}
                            size="small" variant="text" >Pricing</Button>
                        <Button
                            onClick={() => window.location.href = "/changelog"}
                            startIcon={<svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M7 18H17V16H7V18Z" fill="currentColor"></path><path d="M17 14H7V12H17V14Z" fill="currentColor"></path><path d="M7 10H11V8H7V10Z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z" fill="currentColor"></path></svg>}
                            size="small" variant="text" >Changelog</Button>
                    </Box>

                    <Box sx={{ mt: 2 }}>
                        <a href="/platus">
                            <img alt="Platus logo" src={PlatusLogo} style={{ height: "30px", objectFit: "cover", textAlign: "center" }} />
                        </a>
                    </Box>
                    <Typography variant="body2" sx={{ opacity: "0.6", fontSize: "12px", mt: 1 }}>
                        Navigating Your Deployment Budgets with Precision.
                    </Typography>
                    <Button
                        onClick={() => window.open("https://landing.nife.io/request-demo", "_blank")}
                        startIcon={<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path></svg>}
                        size="small" variant="text" >
                        demo

                    </Button>
                    <Box sx={{ mt: 2 }}>
                        <a href="/products">
                            <img alt="Platus logo" src={SyncDriveLogo} style={{ height: "30px", objectFit: "cover", textAlign: "center" }} />
                        </a>
                    </Box>
                    <Typography variant="body2" sx={{ opacity: "0.6", fontSize: "12px", mt: 1 }}>
                        Sync your local to cloud in seconds.
                    </Typography>

                    <Button variant='outlined'
                        endIcon={<ArrowForwardIcon />}
                        onClick={()=> navigate("/products")}
                        sx={{ mt: 2 }} // Add margin-top for spacing
                        fullWidth>
                        View all Products
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default GroupComponent;
