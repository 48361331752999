import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { HelmetProvider } from 'react-helmet-async';
import { Route, BrowserRouter  as Router, Routes } from 'react-router-dom';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ChangeLog from './components/changelog';
import MainLayout from './layouts/MainLayout';
import AboutUsPage from './pages/AboutUsPage';
import ApplicationPartner from './pages/ApplicationPartner';
import Careers from './pages/Careers';
import HomePage from './pages/HomePage';
import InfraPartners from './pages/InfraPartners';
import OikosDetailedPage from './pages/OikosDetailedPage';
import Partner from './pages/Partner';
import Platus from './pages/Platus';
import featuresData from './features/oikos/features.json';
import FeaturesRoutes from './features/oikos/FeaturesRoutes';
import FeaturePage from './features/oikos/FeaturePage';
import ShowAllFeatures from './features/oikos/ShowAllFeatures';
import PlanAndPricing from './components/oikos/OikosPricing';
import Template2 from './features/oikos/Template2';
import PublicationsPage from './components/publications';
import EventsPage from './components/events';
import OpenSourcePage from './components/open-source';
import RuntimeLandingPage from './features/ShowAllRuntimes';
import ShowAllProducts from './features/ShowAllProducts';
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: "#0d6efd" },
  },
});


function App() {
  return (
    <HelmetProvider>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Router>
          <MainLayout>
         
              <Routes>
              <Route path="/oikos/features/*" element={<FeaturesRoutes />} />
                <Route exact path="/" element={<HomePage />} />
                <Route exact path="/about-us" element={<AboutUsPage />} />
                <Route exact path="/changelog" element={<ChangeLog />} />
                <Route exact path="/careers" element={<Careers />} />
                <Route exact path="/publications" element={<PublicationsPage />} />
                <Route exact path="/events" element={<EventsPage />} />
                <Route exact path="/runtimes" element={<RuntimeLandingPage />} />
                <Route exact path="/products" element={<ShowAllProducts />} />
                
                <Route exact path="/open-source" element={<OpenSourcePage />} />
                
                
                <Route exact path="/oikos/features" element={<ShowAllFeatures/>}/>
                <Route exact path="/oikos/pricing" element={<PlanAndPricing/>}/>
                <Route exact path="/deploy/:id" element={<Template2/>}/>
                
                <Route exact path="/partner" element={<Partner />} />
                <Route exact path="/application-partner" element={<ApplicationPartner />} />
                <Route exact path="/infra-partners" element={<InfraPartners />} />
                <Route exact path="/platus" element={<Platus />} />
                <Route exact path="/oikos" element={<OikosDetailedPage/>} />
                {featuresData.features.map(feature => (
                  <Route
                    key={feature.slug}
                    path={`/oikos/features/${feature.slug}`}
                    element={<FeaturePage slug={feature.slug} />}
                />
                ))}
                
                
              </Routes>
           
          </MainLayout>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
